<template>
    <v-btn
        class="app-icon-btn"
        dark
        width="32"
        :color="color"
        :disabled="disabled"
        v-bind="$attrs"
        v-on="$listeners">
        <v-icon>{{ icon }}</v-icon>
    </v-btn>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "primary"
        },
        icon: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>
<style scoped>
.app-icon-btn.v-size--default {
    min-width: 40px;
    height: 40px;
}
</style>
