<template>
    <v-card>
        <v-dialog v-model="valueLocal" persistent max-width="500">
            <v-card>
                <h3 class="px-4 pt-4">
                    {{ title }}
                </h3>
                <div class="px-4 mt-2">
                    {{ message }}
                </div>
                <div class="d-flex pa-2 mt-2">
                    <v-spacer />
                    <v-btn color="primary" text @click="confirmAction">
                        Confirm
                    </v-btn>
                    <v-btn color="error" text @click="cancelAction">
                        Cancel
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Confirm Action'
        },
        message: {
            type: String,
            default: 'Are you sure?'
        },
    },
    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit('input', false);
                }
            }
        }
    },
    methods: {
        confirmAction() {
            this.$emit('confirm');
            this.$emit('input', false);
        },
        cancelAction() {
            this.$emit('cancel');
            this.$emit('input', false);
        }
    }
};
</script>
