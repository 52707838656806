<template>
    <div>
        {{ display }}
    </div>
</template>

<script>
import { format } from "@/formatting/dateTimeFormatter";

export default {
    types: [
        "dateTime",
    ],
    props: {
        value: {
            type: [ String ],
            default: null,
        }
    },
    computed: {
        display() {
            return format(this.value);
        }
    }
};
</script>
