<template>
    <v-form
        ref="form"
        v-model="isValid"
        @submit.prevent="save()">
        <v-card>
            <v-card-title>
                <span class="headline font-weight-bold">
                    {{ isEdit ? 'Edit' : 'Add' }} your job ad
                </span>
            </v-card-title>

            <v-divider />
            <v-card-text>
                <div class="d-flex flex-wrap">
                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <h3>Role Information</h3>
                        <v-divider class="mt-2 mb-4" />
                        <form-search-select
                            v-model="item.siteId"
                            :field="getField('siteId')"
                            autofocus
                            validate-on-blur
                            @save="save" />
                        <schema-search-select
                            v-model="item.skillCategoryId"
                            label="Skill Category"
                            entity-key="skillCategory"
                            :rules="[v => !!v || 'Skill Category is required']"
                            search-when-blank />
                        <form-search-select
                            v-model="item.skillId"
                            :field="getField('skillId')"
                            :filter="skillFilter"
                            use-starts-with
                            validate-on-blur
                            required
                            @save="save" />
                        <form-enum
                            v-model="item.commute"
                            :field="getField('commute')"
                            validate-on-blur
                            @save="save" />
                        <form-enum
                            v-model="item.experience"
                            :field="getField('experience')"
                            validate-on-blur
                            @save="save" />
                    </div>

                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <h3>Pay Details</h3>
                        <v-divider class="mt-2 mb-4" />
                        <form-enum
                            v-model="item.compensationMethod"
                            :field="getField('compensationMethod')"
                            validate-on-blur
                            @change="adjustShiftRate"
                            @save="save" />
                        <app-select
                            v-model="item.compensation"
                            :label="compensationLabel"
                            validate-on-blur
                            required
                            :items="shiftRates"
                            :rules="[v => !!v || 'Compensation is required']"
                            @save="save" />
                        Hiding pay allows search visibility but doesn't display the amount.
                        <form-checkbox
                            v-model="item.isPayHidden"
                            :field="getField('isPayHidden')"
                            validate-on-blur
                            @save="save" />
                        <form-search-select
                            v-model="item.rosterId"
                            :field="getField('rosterId')"
                            validate-on-blur
                            @save="save" />
                    </div>

                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <h3>Branding</h3>
                        <v-divider class="mt-2 mb-4" />
                        <form-file
                            v-model="item.profileFileId"
                            :field="getField('profileFileId')"
                            validate-on-blur
                            hide-title
                            @save="save" />
                    </div>

                    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <h3>Permissions</h3>
                        <v-divider class="mt-2 mb-4" />
                        <form-search-select-multiple
                            v-model="item.teamMembers"
                            :field="getField('teamMembers')"
                            validate-on-blur
                            @save="save" />
                    </div>

                    <div class="col-12">
                        <h3>About</h3>
                        <v-divider class="mt-2 mb-4" />
                        <app-text-area
                            v-if="!fixedValues?.about"
                            v-model="item.about"
                            :value="value"
                            class="full-height"
                            required
                            @save="save" />
                    </div>
                </div>
            </v-card-text>
            <v-divider />
            <v-card-actions class="pa-4">
                <app-button
                    v-if="canDelete"
                    color="error"
                    text
                    @click="deleteItem">
                    Delete
                </app-button>
                <v-spacer />
                <app-button
                    v-if="canCancel"
                    color="secondary"
                    class="text-none"
                    text
                    @click="$emit('cancel')">
                    Cancel
                </app-button>
                <app-button
                    v-if="canSave"
                    type="submit"
                    class="text-none"
                    text
                    :disabled="isSaving">
                    {{ buttonLabel }}
                </app-button>
            </v-card-actions>

            <v-progress-linear v-if="isSaving" indeterminate color="blue" />
        </v-card>
    </v-form>
</template>

<script>
import formMixin from "@/features/schemas/mixins/formMixin";
import { get } from "@/services/apiService";
import { equal } from "@/services/filtering";

export default {
    components: {
        schemaSearchSelect: () => import('@/features/schemas/components/SchemaSearchSelect'),
        formSearchSelect: () => import('@/features/schemas/formFields/FormSearchSelect'),
        formSearchSelectMultiple:
            () => import('@/features/schemas/formFields/FormSearchSelectMultiple'),
        formEnum: () => import('@/features/schemas/formFields/FormEnum'),
        formFile: () => import('@/features/schemas/formFields/FormFile'),
        formCheckbox: () => import('@/features/schemas/formFields/FormCheckbox'),
    },
    mixins:[ formMixin ],
    types: [
        'job'
    ],
    data() {
        return {
            settingSkillCategory: false
        }
    },
    computed: {
        skillFilter() {
            return this.item.skillCategoryId ?
                equal('skillCategoryId', this.item.skillCategoryId) : null;
        },
        buttonLabel() {
            if (this.item.jobStatus == 1) {
                return this.isEdit ? 'Update Draft' : 'Add Draft'
            }

            return this.isEdit ? 'Update Job' : 'Post Job'
        },
        compensationLabel() {
            switch(this.item.compensationMethod) {
                case 'Shift':
                    return 'Shift Rate';

                case 'Hourly':
                    return 'Hourly Rate';

                case 'Annual':
                    return 'Salary';
            }

            return 'Rate';
        },
        shiftRates() {
            let [minRate, maxRate, incrementStep] = [0,0,0];
            switch (this.item.compensationMethod) {
                case 'Hourly': {
                    [minRate, maxRate, incrementStep] = [20, 210, 5];
                    break;
                }
                case 'Shift': {
                    [minRate, maxRate, incrementStep] = [250, 2500, 50];
                    break;
                }
                case 'Annual': {
                    [minRate, maxRate, incrementStep] = [40000, 600000, 5000];
                    break;
                }
                default: {
                    return [];
                }
            }
            const rates = [];
            for (let i = minRate; i <= maxRate; i = i + incrementStep) {
                rates.push(i);
            }
            return rates;
        }
    },
    watch: {
        'item.skillCategoryId'() {
            if (this.settingSkillCategory) {
                this.settingSkillCategory = false;
                return;
            }
            this.$set(this.item, 'skillId', null);
        },
        'item.skillId': {
            async handler() {
                if (this.item.skillCategoryId || !this.item.skillId) {
                    return;
                }
                let skill = await get('skills', this.item.skillId);
                this.settingSkillCategory = true;
                this.$set(this.item, 'skillCategoryId', skill.skillCategoryId);
            }
        }
    },
    methods: {
        adjustShiftRate() {
            if (this.shiftRates.length < 1) {
                this.item.compensation = undefined;
                return;
            }
            const minimumShiftRate = this.shiftRates[0];
            this.item.compensation = minimumShiftRate;
        },
        getField(key) {
            return this.fields.find(e => e.key === key);
        }
    }
}
</script>
