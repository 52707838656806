<template>
    <schema-search-select-multiple
        v-if="field && field.dependsOn"
        class="flex-grow-1"
        v-bind="$attrs"
        :value="value"
        :label="field.title"
        :rules="rules"
        :entity-key="field.dependsOn.entityKey"
        search-when-blank
        v-on="$listeners" />
</template>

<script>
import formFieldMixin from "./formFieldMixin";

export default {
    types: ["entities"],
    mixins: [formFieldMixin],
    props: {
        value: {
            type: Array,
            default: () => []
        }
    }
}
</script>
