import { subscribeToChat } from '@/features/chat/chatService';
import { list } from '@/features/schemas/services/schemaApi';
import { createCompletion } from '@/services/completion';
import { mutateArray } from '@/services/reactiveArrays';

function sortChats(chats) {
    chats.sort((a, b) => {
        const aDate = new Date(a.lastActivityAt || 0);
        const bDate = new Date(b.lastActivityAt || 0);
        return bDate.getTime() - aDate.getTime();
    })
}

const getDefaultState = () => ({
    chats: [],
    isLoaded: false,
    loadCompletion: createCompletion(),
    chatSubscription: null
});

const state = getDefaultState();

const actions = {
    async load({ commit }) {
        try {
            const { items } = await list('chat');
            sortChats(items);
            commit('setChats', items);
            const chatSubscription = subscribeToChat((items, action) => {
                commit('receiveChats', { items, action });
            });
            commit('chatSubscription', chatSubscription);
        } finally {
            commit('completeLoad');
        }
    },

    clear({ commit }) {
        commit('clearChats');
    },
};

const mutations = {
    setChats(state, chats) {
        state.chats = chats;
    },
    receiveChats(state, { items, action }) {
        mutateArray(state.chats, items, action);
        sortChats(state.chats);
    },

    clearChats(state) {
        if(state.chatSubscription) {
            state.chatSubscription.unsubscribe();
        }
        Object.assign(state, getDefaultState());
    },
    completeLoad(state) {
        state.isLoaded = true;
        state.loadCompletion.complete();
    },
    chatSubscription(state, subscription) {
        if(state.chatSubscription) {
            state.chatSubscription.unsubscribe();
        }
        state.chatSubscription = subscription;
    }
};

const getters = {
    unreadCount: (state, _, rootState) => {
        const teamMemberId = rootState.authentication.user?.teamMemberId;
        return state
            .chats
            .filter(c => c.teamMemberId === teamMemberId && c.lastMessage != null)
            .reduce((acc, chat) => acc + chat.unreadCount, 0)
    },
    chats: state => state.chats,
};

export const chat = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
