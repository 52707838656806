export function round(value, precision) {
    let multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function isInt32Number(number) {
    if (number == null) return false
    if (typeof number !== "number") return false

    if (!Number.isInteger(number)) return false

    const MIN_INT32 = -Math.pow(2, 31);
    const MAX_INT32 = Math.pow(2, 31) - 1;

    return number >= MIN_INT32 && number <= MAX_INT32
}

export function isDecimalNumber(stringValue) {
    if (stringValue == null) return false

    if (stringValue.length > 30) return false

    const possibleNumber = +stringValue

    if (isNaN(possibleNumber)) return false

    const parts = stringValue.split('.');
    const integralPart = parts[0];
    const fractionalPart = parts[1] || '';

    return integralPart.length <= 20 && fractionalPart.length <= 10
}

export default { round, isInt32Number, isDecimalNumber };
