
const state = {
    backgroundColor: 'white'
};

const mutations = {
    setBackgroundColor(state, color) {
        state.backgroundColor = color
    },
    resetBackgroundColor(state) {
        state.backgroundColor = 'white'
    }
}

export const appBar = {
    namespaced: true ,
    state,
    mutations
}
