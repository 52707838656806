<template>
    <v-chip
        v-if="value"
        class="mt-n1 clickable"
        dark
        small
        label
        :color="chipColor"
        @click="invite">
        {{ display }}
    </v-chip>
</template>

<script>
import axios from 'axios';

export default {
    types: [
        "teamMemberStatus",
    ],
    props: {
        value: {
            type: String,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
    },
    computed: {
        chipColor() {
            switch (this.value) {
                case "Inactive":
                    return "secondary";
                case "Invited":
                    return "quinary";
                case "Active":
                    return "primary";
                default:
                    return "grey";
            }
        },
        display() {
            switch (this.value) {
                case "Inactive":
                    return "Send Invite";
                case "Invited":
                    return "Resend Invite";
                case "Active":
                    return "Active";
                default:
                    return "Unknown";
            }
        }
    },
    methods: {
        async invite() {
            if (this.value == "Active") {
                return;
            }
            // TODO: use the schema services, not axios directly.
            await axios.post("/api/TeamMemberInvites/Invite", { teamMemberId: this.item.id })
            //inform the user that the invite was sent using default browser dialog
            alert("Invite sent");
            this.$emit('refresh')
        }
    }
};
</script>
