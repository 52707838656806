export function buildListRequest(dataTableOptions) {
    if(dataTableOptions == null) {
        return null;
    }

    const o = dataTableOptions;

    return {
        skip: o.itemsPerPage === -1 ?
            null :
            (o.page - 1) * o.itemsPerPage,

        take: o.itemsPerPage === -1 ?
            null :
            o.itemsPerPage,

        sortBy: o.sortBy?.length ?
            o.sortBy[0] :
            null,

        direction: o.sortDesc.length && o.sortDesc[0] ?
            "descending" :
            "ascending"
    };
}
