const actions = {
    add,
    update,
    remove,
    get: () => null
}

export function mutateArray(target, changes, action) {
    const mutate = actions[action] || null
    mutate(target, changes)
}

function add(target, changes) {
    target.push(...changes)
}

function update(target, changes) {
    changes.forEach(change => {
        updateItem(target, change)
    })
}

function updateItem(target, change) {
    const index = target.findIndex(item => item.id === change.id)
    if (index !== -1) {
        target.splice(index, 1, change)
    } else {
        target.push(change)
    }
}

function remove(target, changes) {
    const idsToDelete = new Set(changes.map(item => item.id))

    for (let i = target.length - 1; i >= 0; i--) {
        if (idsToDelete.has(target[i].id)) {
            target.splice(i, 1)
        }
    }
}
