import { connectToHub, closeHubConnection } from "@/services/hub"
import chat from "@/features/chat/chatService"

function getDefaultState() {
    return {
        connection: null,
        isLoaded: false
    };
}

const state = getDefaultState;

const actions = {
    async load({ commit, dispatch }) {
        const connection = await connectToHub();
        commit("connection", connection);
        chat.load();
        dispatch('workerLikes/load', null, { root: true })
        commit("isLoaded", true);
    },

    clear({ commit, state, dispatch }) {
        chat.clear();
        dispatch('workerLikes/clear', null, { root: true })
        closeHubConnection(state.connection);
        commit("clear");
    }
};

const mutations = {
    connection(state, connection) {
        state.connection = connection;
    },
    isLoaded(state, value) {
        state.isLoaded = value;
    },
    clear(state) {
        Object.assign(state, getDefaultState());
    }
};

export const hub = {
    namespaced: true,
    state,
    actions,
    mutations
};
