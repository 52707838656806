import { subscribe } from '@/services/hub';
import { createCompletion } from '@/services/completion';
import { list, delete_ } from '@/features/schemas/services/schemaApi';

const getDefaultState = () => ({
    workerLikes: [],
    isLoaded: false,
    loadCompletion: createCompletion(),
    subscription: null
});

const state = getDefaultState();

const actions = {
    async load({ commit, state }) {
        function recieveMessage(message) {
            if (message.action !== "add" || message.entityKey !== "unviewedWorkerLike") return

            commit('recieveWorkerLikes', message.items)
        }

        try {
            const { items } = await list('unviewedWorkerLike');
            commit('setWorkerLikes', items);

            if(state.subscription) {
                throw new Error("WorkerLikes subscriptipn already loaded. Call clear first.");
            }

            const subscription = subscribe(recieveMessage, [ "unviewedWorkerLike" ]);

            commit('subscription', subscription);
        } finally {
            commit('completeLoad');
        }
    },

    clear({ commit }) {
        commit('clearWorkerLikes');
    },
};

const mutations = {
    setWorkerLikes(state, items) {
        state.workerLikes = items;
    },
    recieveWorkerLikes(state, items) {
        state.workerLikes = [...state.workerLikes, ...items]
    },

    clearWorkerLikes(state) {
        if(state.subscription) {
            state.subscription.unsubscribe();
        }
        Object.assign(state, getDefaultState());
    },
    completeLoad(state) {
        state.isLoaded = true;
        state.loadCompletion.complete();
    },
    subscription(state, subscription) {
        if(state.subscription) {
            state.subscription.unsubscribe();
        }
        state.subscription = subscription;
    },
    async deleteWorkerLikes(state, workerIds) {
        for(let i = 0; i < state.workerLikes.length; i++) {
            const workerLike = state.workerLikes[i];

            if (!workerIds.has(workerLike.workerSwipe.workerId)) continue;

            try {
                await delete_("unviewedWorkerLike", workerLike.id);
            } catch {
                // Probably fails because worker unlikes a job as a team member views the job.
            }finally {
                state.workerLikes.splice(i, 1)
                i--
            }


        }
    }
};

function getUniqueWorkerIds(workerLikes) {
    const uniqueWorkerIds = new Set();

    for(const workerLike of workerLikes) {
        if (workerLike?.workerSwipe?.workerId) {
            uniqueWorkerIds.add(workerLike.workerSwipe.workerId)
        }
    }

    return uniqueWorkerIds;
}

const getters = {
    unviewedLikesCount: state => getUniqueWorkerIds(state.workerLikes).size,
    workerLikes: state => state.workerLikes,
};

export const workerLikes = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
