<template>
    <v-autocomplete
        v-bind="$attrs"
        dense
        :filter="useStartsWith ? startsWithFilter : undefined"
        outlined
        v-on="$listeners" />
</template>

<script>
export default {
    props: {
        useStartsWith: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        startsWithFilter(item, queryText) {
            return item.text.toLowerCase().startsWith(queryText.toLowerCase());
        }
    }
}
</script>
