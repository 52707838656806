import { subscribe } from "@/services/hub";
import { groupBy } from "@/services/arrayUtility";
import { DateTime } from "luxon";

let subscription = null;
let chatSubscriptions = []
let chatMessageSubscriptions = [];

export function load() {
    if(subscription) {
        throw new Error("Chat service already loaded. Call clear first.");
    }
    subscription = subscribe(receiveMessage, [ "chatMessage", "chat" ]);
}

export function clear() {
    if(subscription?.unsubscribe) {
        subscription.unsubscribe();
        subscription = null;
    }
    chatSubscriptions = [];
}

export function subscribeToMessages(chatId, onAddOrUpdate) {
    let subscription = { chatId, onAddOrUpdate };
    subscription.unsubscribe = () => unsubscribe(chatMessageSubscriptions, subscription);
    chatMessageSubscriptions.push(subscription);
    return subscription;
}

export function subscribeToChat(onAddOrUpdate) {
    let subscription = { onAddOrUpdate };
    subscription.unsubscribe = () => unsubscribe(chatSubscriptions, subscription);
    chatSubscriptions.push(subscription);
    return subscription;
}

function receiveMessage(message) {
    switch(message.entityKey) {
        case "chatMessage":
            receiveChatMessages(message);
            break;
        case "chat":
            receiveChats(message);
            break;
    }
}

function receiveChatMessages(message) {
    const itemsByChatId = groupBy(message.items, i => i.chatId);

    const chatIds = Object
        .keys(itemsByChatId)
        .map(chatId => parseInt(chatId));

    for(let i = 0; i < chatIds.length; i++) {
        const chatId = chatIds[i];

        const thisChatSubscriptions = chatMessageSubscriptions
            .filter(s => s.chatId === chatId);

        sendToSubscriptions(
            thisChatSubscriptions,
            message.action,
            itemsByChatId[chatId]);
    }
}

function receiveChats(message) {
    sendToSubscriptions(chatSubscriptions, message.action, message.items);
}

function sendToSubscriptions(subscriptions, action, items) {
    for(let i = 0; i < subscriptions.length; i++) {
        let subscription = subscriptions[i];
        sendToSubscription(subscription, action, items);
    }
}

function sendToSubscription(subscription, action, items) {
    switch(action) {
        case "add":
        case "update":
            subscription.onAddOrUpdate(items, action);
            break;
        // TODO: Handle delete
    }
}

function unsubscribe(subscriptions, subscription) {
    const index = subscriptions.indexOf(subscription);
    if (index > -1) {
        subscriptions.splice(index, 1);
    }
}

export function addChainAnnotations(messages) {
    for(let i = 0; i < messages.length; i++) {
        const message = messages[i];
        message.startOfChain = isStartOfChain(message, messages, i);
        if(i == 0) {
            continue;
        }
        const previous = messages[i - 1];
        previous.endOfChain = message.startOfChain;
    }

    if(messages.length) {
        messages[messages.length - 1].endOfChain = true;
    }
}

function isStartOfChain(message, messages, index) {
    if (index === 0) {
        return true;
    }
    const previous = messages[index - 1];

    if(previous.teamMemberId !== message.teamMemberId) {
        return true;
    }

    // If the previous message was sent more than 1 hour ago,
    // then this message is the start of a new chain.
    const previousSentAt = DateTime.fromISO(previous.sentAt);
    const currentSentAt = DateTime.fromISO(message.sentAt);
    return currentSentAt.diff(previousSentAt).as('hours') > 1;
}

export default {
    load,
    clear,
    subscribeToMessages,
    subscribeToChat,
    addChainAnnotations
}
