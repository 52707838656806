<template>
    <v-chip
        v-if="value"
        class="no-overflow-ellipsis"
        dark
        small
        label
        :color="chipColor">
        {{ value }}
    </v-chip>
</template>

<script>
export default {
    types: [
        "status",
    ],
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    computed: {
        chipColor() {
            switch (this.value) {
                case "Draft":
                    return "grey";
                case "Open":
                    return "primary";
                case "Expired":
                    return "error";
                case "Withdrawn":
                    return "quinary";
                default:
                    return "grey";
            }
        },
    },
};
</script>
