<template>
    <v-checkbox
        :input-value="value"
        v-bind="$attrs"
        :label="field.title"
        :rules="rules"
        class="pt-0 mt-0"
        @change="$emit('input', $event)"
        v-on="$listeners" />
</template>

<script>
import formFieldMixin from "./formFieldMixin";

export default {
    types: ["bool"],
    mixins: [formFieldMixin],
    props: {
        value: {
            type: Boolean,
            default: null,
        }
    },
    mounted() {
        if (this.value == null) {
            this.$emit("input", false);
        }
    }
};
</script>
