<template>
    <!-- This is ok because we are using the dompurify package to sanitize the HTML. -->
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="preserve-newline" v-html="sanitizedContent" />
</template>

<script>
import domPurify from "dompurify";

export default {
    props: {
        content: {
            type: String,
            default: null
        }
    },
    computed: {
        sanitizedContent() {
            return domPurify.sanitize(this.content);
        }
    }
};
</script>
