export const types = [
    "currency"
];

export function format(value) {
    if (!value) {
        return "$0";
    }
    return value.toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
}
