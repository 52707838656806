let appTableView = () =>
    import(
        /* webpackChunkName: "schemaTableView" */ "@/features/schemas/views/SchemaTableView.vue");

export default [
    {
        path: "/action-logs",
        name: "actionLogs",
        staticProps: { entityKey: "actionLog" },
    },
    {
        path: "/files",
        name: "files",
        staticProps: { entityKey: "file" },
    },
    {
        path: "/people",
        name: "people",
        staticProps: { entityKey: "person" },
    },
    {
        path: "/settings",
        name: "settings",
        staticProps: { entityKey: "setting" },
    },
    {
        path: "/users",
        name: "users",
        staticProps: { entityKey: "user" },
    },
    {
        path: "/user-roles",
        name: "userRoles",
        staticProps: { entityKey: "userRole" },
    },
].map(e => {
    return { ...e, component: appTableView };
});
