import { pascalToCamelCase } from "./stringUtility";

const states = {
    australianCapitalTerritory: "ACT",
    newSouthWales: "NSW",
    northernTerritory: "NT",
    queensland: "QLD",
    southAustralia: "SA",
    tasmania: "TAS",
    victoria: "VIC",
    westernAustralia: "WA"
};

const acronymToState = Object.fromEntries(
    Object.entries(states).map(([key, value]) => [value, key])
);

export function getStateAcronym(stateName) {
    let camelCaseState = pascalToCamelCase(stateName);
    return states[camelCaseState] || null;
}

export function getStateName(acronym) {
    return acronymToState[acronym] || null;
}

export function getStatesArray() {
    return Object.entries(states).map(([value, text]) => ({ value, text }));
}
