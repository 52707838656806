import { getLocale, getTimeZone } from "@/services/localisation";
import { DateTime } from "luxon"

export const types = [
    "datetime"
]

export function format(value, format = null) {
    if (value == null) {
        return "";
    }

    // All datetime values are in UTC. If you don't want this, use datetimeoffset.
    // For display, we convert UTC dates to the user's local time.
    // Datetime values are sent from the server with the suffix "Z".
    // In the absense of an offset, the date will be interpreted as UTC before converting.
    let dateTime = DateTime
        .fromISO(value, { setZone: true, zone: "utc" }) // setZone will override zone here.
        .setLocale(getLocale())
        .setZone(getTimeZone());

    if(format == null) {
        const noTime = (
            dateTime.hour === 0 &&
            dateTime.minute === 0 &&
            dateTime.second === 0 &&
            dateTime.millisecond === 0);

        format = noTime ?
            DateTime.DATE_SHORT :
            DateTime.DATETIME_SHORT;
    }

    return dateTime
        .toLocaleString(format);
}
