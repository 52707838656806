<template>
    <v-btn
        class="px-0 app-btn"
        :color="color"
        elevation="2"
        large
        v-bind="$attrs"
        v-on="$listeners">
        <v-icon v-if="icon" class="app-btn-icon">
            {{ icon }}
        </v-icon>
        <span class="px-5 flex-grow-1"><slot /></span>
    </v-btn>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "primary"
        },
        icon: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/theme.scss";

.app-btn-icon {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    font-size: 22px;
    margin-left: 10px;
    margin-right: -20px;
}
.app-btn.v-size--large {
    color: #ffffff;
    font-size: 1rem;
    border-radius:2px;
    border-width: 2px;
}

</style>
