import { handleError } from "@/services/errorUtility";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import { pascalToCamelCase } from "./stringUtility";

const subscriptions = [];

export async function connectToHub() {
    const connection = new HubConnectionBuilder()
        .withUrl("/hub")
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

    connection.on("receiveasync", onReceive);
    await connection.start();
    return connection;
}

export async function closeHubConnection(connection) {
    connection.off("receiveasync");
    connection.stop();
}

export function subscribe(handler, entityKeys) {
    let subscription = {
        handler,
        entityKeys: new Set(entityKeys)
    };
    subscription.unsubscribe = () => unsubscribe(subscription);
    subscriptions.push(subscription);
    return subscription;
}

function unsubscribe(subscription) {
    const index = subscriptions.indexOf(subscription);
    if (index > -1) {
        subscriptions.splice(index, 1);
    }
}

function onReceive(message) {
    ensureCamelCase(message)

    for(let i = 0; i < subscriptions.length; i++) {
        let subscription = subscriptions[i];
        // Only include the message if the subscription is interested in the entity.
        if(!subscription.entityKeys.has(message.entityKey)) {
            continue;
        }
        try {
            subscription.handler(message);
        }
        catch(e) {
            handleError(e);
        }
    }
}

function ensureCamelCase(message) {
    message.entityKey = pascalToCamelCase(message.entityKey);
    message.action = pascalToCamelCase(message.action);
}
