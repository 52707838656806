<template>
    <schema-table
        :key="item.entity.key"
        class="flex-grow-1 mt-3"
        :entity-key="item.entity.key"
        :fixed-values="getFixedValues(item)" />
</template>

<script>
import { getEntity } from "@/features/schemas/services/schemaProvider";
import { get } from "@/features/schemas/services/schemaApi";

export default {
    types: [
        "default"
    ],
    props: {
        itemId: {
            type: [Number, String],
            default: null
        },
        item: {
            type: Object,
            default: null
        },
        entityKey: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            parentItem: null
        }
    },
    watch: {
        itemId: {
            immediate: true,
            handler(newValue) {
                this.loadItem(newValue);
            }
        }
    },
    methods: {
        async loadItem(itemId) {
            if (!itemId) {
                return;
            }
            this.parentItem = await get(this.entityKey, itemId);
        },
        getFixedValues(item) {
            let result = {};
            result[item.field.key] = this.itemId;

            this.setChildFixedValues(result)
            return result;
        },
        setChildFixedValues(result) {
            if (!this.parentItem) {
                return;
            }
            let childEntity = getEntity(this.item.entity.key);
            let parentEntity = getEntity(this.entityKey);

            // We need to ensure that child tables have the correct filters applied
            // if the parent entity shares a foreign key with one of them
            for (let childQueryFieldKey in childEntity.queryFields) {
                let childQueryField = childEntity.queryFields[childQueryFieldKey];

                for (let parentQueryFieldKey in parentEntity.queryFields) {
                    let parentQueryField = parentEntity.queryFields[parentQueryFieldKey];

                    if (!parentQueryField.dependsOn?.entityKey) {
                        continue;
                    }
                    if (childQueryField.dependsOn.entityKey
                        === parentQueryField.dependsOn.entityKey) {
                        result[childQueryField.key] = this.parentItem[parentQueryField.key];
                    }
                }
            }
        }
    }
}
</script>
