<template>
    <div ref="container" class="canvas-container">
        <canvas id="my-canvas" ref="canvas" />
        <div class="slot-content">
            <slot />
        </div>
    </div>
</template>
<script>
import MeshGradient from 'mesh-gradient.js';

export default {
    props: {
        palette: {
            type: Array,
            default: null,
        },
    },

    data() {
        return {
            gradient: null,
        };
    },
    computed: {
        colors() {
            if (!this.palette?.length) {
                return ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"];
            }
            return this.palette;
        }
    },
    watch: {
        colors: {
            immediate: true,
            handler() {
                this.setGradient();
            },
        },
    },

    mounted() {
        this.gradient = new MeshGradient();
        this.setGradient();
        window.addEventListener('resize', this.setGradient);
    },

    beforeDestroy() {
        this.gradient.disconnect(); // Don't leave Web GL instance alive.
        this.gradient = null;
        window.removeEventListener('resize', this.setGradient);
    },

    methods: {
        setGradient() {
            if (!this.gradient) {
                return;
            }
            const canvasId = "my-canvas";
            this.gradient.initGradient("#" + canvasId, this.colors);
            this.gradient.changePosition(780);
        },
    }
};
</script>
<style scoped>
.canvas-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

canvas {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.slot-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
