<template>
    <div class="d-flex flex-column schema-table">
        <div v-if="!tableOnly" class="d-flex align-center mb-5">
            <slot name="title">
                <h2 class="mr-3">
                    {{ title ? title : entity.pluralTitle }}
                </h2>
            </slot>
            <app-text-field
                v-model="searchText"
                class="no-print"
                label="Search"
                hide-details
                prepend-inner-icon="mdi-magnify" />

            <slot name="add-button">
                <app-button
                    v-if="canAdd"
                    class="ml-4 no-print text-none font-large"
                    color="primary"
                    @click="add">
                    Add a {{ buttonLabel ?
                        buttonLabel.toLowerCase() :
                        entity.singleTitle.toLowerCase() }}
                </app-button>
            </slot>

            <schema-excel-export-button
                v-if="!disableExport"
                v-bind="$attrs"
                class="ml-4 no-print"
                :entity-key="entityKey" />
        </div>

        <v-card :class="cardClasses" :elevation="outlined ? 0 : 4">
            <component
                :is="tableComponent"
                ref="schemaDataTable"
                class="flex-grow-1"
                v-bind="$attrs"
                :entity-key="entityKey"
                :search-text="searchText"
                :readonly="readonly"
                :edit-disabled="editDisabled"
                :has-item-page="hasItemPage"
                @edit="edit">
                <template #prepend-actions="{ item }">
                    <slot name="prepend-actions" :item="item" />
                </template>
                <template #extra-actions="{ item }">
                    <slot name="extra-actions" :item="item" />
                </template>
            </component>
        </v-card>

        <schema-form-dialog
            :entity-key="entityKey"
            :value="editedItem"
            :form="form"
            v-bind="$attrs"
            @input="onItemSaved" />
    </div>
</template>

<script>
import schemaMixin from "@/features/schemas/mixins/schemaMixin";
import { getEntity } from "@/features/schemas/services/schemaProvider"
import tables from "@/features/schemas/tables";

export default {
    mixins:[schemaMixin],
    props: {
        form: {
            type: Function,
            default: null
        },
        disableExport: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: true
        },
        tableOnly: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        buttonLabel: {
            type: String,
            default: null
        },
        editDisabled: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            editedItem: null,
            searchText: "",
        };
    },

    computed: {
        cardClasses() {
            return {
                'flex-grow-1': true,
                'd-flex': true,
                'flex-column': true,
                'pa-2': true,
                'outlined-card': this.outlined
            }
        },
        entity() {
            return getEntity(this.entityKey);
        },
        tableComponent() {
            return tables.getComponent(this.entity);
        },
    },

    methods: {
        add() {
            this.editedItem = {};
        },
        edit(item) {
            this.editedItem = item;
        },
        async onItemSaved() {
            this.editedItem = null;
            if (this.$refs.schemaDataTable) {
                await this.$refs.schemaDataTable.refresh();
            }
        }
    }
};
</script>
<style scoped>
.v-btn.font-large {
    font-size: 20px;
}
</style>
