<template>
    <div>
        <div class="font-weight-bold">
            {{ label }}
        </div>
        <tiptap-vuetify
            v-bind="$attrs"
            :extensions="extensions"
            v-on="$listeners" />
    </div>
</template>

<script>
import {
    TiptapVuetify,
    Bold,
    Italic,
    Strike,
    Underline,
    BulletList,
    OrderedList,
    ListItem } from 'tiptap-vuetify'

export default {
    components: {
        TiptapVuetify
    },
    props: {
        label: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            extensions: [
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                Bold
            ]
        };
    },
}
</script>
