import { routeIsPublic } from "@/services/routeService"

const loadOnStartup = [
    "authentication",
    "schema"
];

const loadOnLoginChange = [
    "localisation"
];

const loadLastOnLoginChange = [
    "hub",
    "chat",
]

const allLoadableStores = [
    ...loadOnStartup,
    ...loadOnLoginChange,
    ...loadLastOnLoginChange
];

function getStores(currentRoute) {
    return routeIsPublic(currentRoute) ?
        loadOnStartup :
        allLoadableStores;
}

async function dispatchAll(stores, action, dispatch) {
    const promises = stores
        .map(store => `${store}/${action}`)
        .map(actionPath => dispatch(actionPath, null, { root: true }));

    await Promise.all(promises);
}

const actions = {
    async onStartup({ dispatch }) {
        await dispatchAll(loadOnStartup, "load", dispatch);
    },

    async onLoginChange({ dispatch }, isLoggedIn) {
        const action = isLoggedIn ? "load" : "clear";
        await dispatchAll(loadOnLoginChange, action, dispatch);
        await dispatchAll(loadLastOnLoginChange, action, dispatch);
    }
};

export const getters = {
    isLoaded: (_state, _getters, rootState) => {
        const stores = getStores(rootState.router.currentRoute);
        return stores.every(store => rootState[store].isLoaded)
    },
    errors: (_state, _getters, rootState) => {
        const stores = getStores(rootState.router.currentRoute);

        return stores
            .map(store => rootState[store].error)
            .filter(error => error);
    }
};

export const load = {
    namespaced: true,
    state: { },
    actions,
    getters
}
