<template>
    <div v-if="file">
        <v-card
            dark
            class="rounded-lg flex-grow-1">
            <v-img
                v-if="file?.isImage"
                class="thumbnail-image rounded-lg"
                height="165"
                max-width="165"
                :src="imageUrl"
                @click="dialog = true" />
            <div v-else class="thumbnail-file">
                <v-icon>
                    mdi-file-document
                </v-icon>
                <span class="my-auto mx-4">
                    {{ file?.name }}
                </span>
            </div>
            <v-btn
                v-if="!readonly && canDelete"
                fab
                x-small
                absolute
                bottom
                left
                color="#00000099"
                class="my-6 no-print"
                @click="deleteFile(file?.id)">
                <v-icon>
                    mdi-trash-can-outline
                </v-icon>
            </v-btn>
            <v-btn
                fab
                x-small
                absolute
                bottom
                right
                color="#00000099"
                class="my-6 no-print"
                @click="downloadFile(file.id)">
                <v-icon>
                    mdi-download
                </v-icon>
            </v-btn>
        </v-card>
        <v-dialog
            v-if="!readonly || !file.isImage"
            v-model="dialog"
            width="unset"
            max-width="1100">
            <v-card color="white">
                <v-card-title class="image-card-title">
                    <span>{{ file?.name }}</span>
                    <v-spacer />
                    <v-btn text @click="dialog = false">
                        Close
                    </v-btn>
                </v-card-title>
                <v-img contain max-height="700" :src="imageUrl">
                    <v-btn
                        v-if="canDelete"
                        class="mx-2 my-10"
                        absolute
                        bottom
                        left
                        small
                        fab
                        dark
                        color="#8c8c8c99"
                        @click="deleteFile(file.id)">
                        <v-icon dark color="red">
                            mdi-trash-can-outline
                        </v-icon>
                    </v-btn>
                </v-img>
            </v-card>
        </v-dialog>
        <img
            v-if="readonly && file.isImage"
            class="full-width"
            :src="imageUrl">
    </div>
</template>

<script>
import { downloadFile } from "@/services/fileService";
import { imageUrl } from '@/services/imageService'

export default {
    props: {
        file: {
            type: Object,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
        canDelete: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            dialog: false
        }
    },

    computed: {
        imageUrl() {
            return imageUrl(this.file.id, 165)
        }
    },

    methods: {
        deleteFile(id) {
            if (!confirm("Are you sure you want to delete this item?")) {
                return;
            }

            this.$emit('delete', id);
            this.dialog = false;
        },
        downloadFile
    }
}
</script>

<style lang="scss" scoped>
    @import "@/styles/theme.scss";

    .thumbnail-image {
        cursor: pointer;
        border: 1px solid $color-grey-light;
    }

    .thumbnail-image:hover {
        -webkit-transform: scale(1.04); // For chrome
        transform: scale(1.04);
    }

    .thumbnail-file {
        display: flex;
        height: 165px;
        width: 165px;
    }

    .thumbnail-file > i {
        position: absolute;
        top: 12px;
        width: 100%;
        color: $color-grey-dark;
    }

    .thumbnail-file > span {
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 4.5em;
        line-height: 1.5em;
    }

    .image-card-title {
        background-color: $color-grey-light;
    }

    .image-card-title > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 88%;
    }
</style>
