<template>
    <div>
        {{ display }}
    </div>
</template>

<script>
import { getLabel } from "@/features/schemas/services/labeller.js";

export default {
    types: [
        "entities"
    ],
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        display() {
            let lookup = this.options?.lookup;
            if(!lookup || !this.value.length) {
                return "";
            }

            return this.value.map(id => {
                let item = lookup[id.toString()];
                return getLabel(this.options.entityKey, item);
            }).join(', ');
        }
    }
};
</script>
