<template>
    <div>
        <v-card
            v-if="item"
            class="rounded-card"
            elevation="0"
            min-width="300">
            <v-card-text>
                <div class="d-flex">
                    <h2 class="mt-2">
                        {{ entity.singleTitle }}
                    </h2>
                    <v-spacer />
                    <app-icon-button
                        icon="mdi-pencil"
                        @click="edit" />
                </div>
                <div
                    v-for="field in fields"
                    :key="field.key"
                    class="mb-2"
                    @click="viewItem(field, item[field.key])">
                    <label>
                        {{ field.title }}
                    </label>
                    <div>
                        <component
                            :is="getCellComponent(field.key)"
                            :key="field.key"
                            :class="{ 'link-text': hasEditView(field) }"
                            :options="getCellOptions(field.key)"
                            :value="item[field.key]" />
                    </div>
                </div>
            </v-card-text>
        </v-card>

        <schema-form-dialog
            :entity-key="entityKey"
            :value="editedItem"
            @input="onItemSaved" />
    </div>
</template>

<script>
import {
    getCellComponent,
    buildCellOptions,
    getTableData,
    getLookupTypes,
    updateLookups,
    getFields
} from "@/features/schemas/services/tableService";

import { equal } from "@/services/filtering";
import schemaMixin from '@/features/schemas/mixins/schemaMixin'
import { getTableRoute } from '@/features/schemas/services/schemaRouteProvider'
import { subscribe } from "@/services/hub";
import { goTo } from "@/services/routeService";
import { distinctBy } from "@/services/arrayUtility";

export default {
    mixins: [schemaMixin],

    props: {
        itemId: {
            type: Number,
            default: null
        }
    },

    data() {
        return {
            tableData: null,
            isDeleted: false,
            editedItem: null
        };
    },

    computed: {
        fields() {
            const fields = getFields(this.entityKey);
            // As with the table service, this prevents rendering fields with a duplicate title.
            // TODO: Prefer inline data to lookups.
            return distinctBy(fields, f => f.title);
        },
        item() {
            const items = this.tableData?.items;
            if(!items?.length) {
                return null;
            }
            return items[0];
        }
    },

    watch: {
        $route: {
            immediate: true,
            async handler() {
                await this.refresh();
            }
        },
    },
    mounted() {
        let entityKeys = [
            this.entityKey,
            ...getLookupTypes(this.entityKey)
        ];
        this.subscription = subscribe(this.receiveMessages, entityKeys);
    },
    destroyed() {
        this.subscription?.unsubscribe();
    },
    methods: {
        getCellComponent(fieldKey) {
            return getCellComponent(this.entityKey, fieldKey);
        },
        getCellOptions(fieldKey) {
            return buildCellOptions(this.tableData, fieldKey);
        },
        async refresh() {
            if(!this.itemId) {
                this.tableData = null
                return;
            }

            const filter = equal("id", this.itemId, "int");
            this.tableData = await getTableData(this.entityKey, { filter }, true);
            if(this.item == null) {
                // Go to table route. This can happen if the item is deleted while viewing it.
                let route = getTableRoute(this.entityKey) ?? "/";
                goTo(route);
            }
        },
        async receiveMessages(messages) {
            const thisItemHasUpdates = messages
                .some(m =>
                    m.entityKey == this.entityKey &&
                    m.item.id == this.itemId);

            if(thisItemHasUpdates) {
                await this.refresh();
                return;
            }

            await updateLookups(this.tableData.lookups, messages);
        },
        edit() {
            this.editedItem = this.item;
        },
        async onItemSaved() {
            this.editedItem = null;
        },
        viewItem(field, id) {
            if(!this.hasEditView(field)) {
                return;
            }
            this.$router.push({
                name: field.dependsOn.entityKey,
                params: { itemId: id }
            });
        },
        hasEditView(field) {
            return "dependsOn" in field;
        }
    }
}
</script>

<style scoped>
label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75rem;
    font-weight: 700;
}
.link-text {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}
</style>
