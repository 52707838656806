export function imageUrl (imageId, minWidth = null, minHeight = null) {
    const query = [];
    if(minWidth != null) {
        query.push(`minWidth=${minWidth}`);
    }
    if(minHeight != null) {
        query.push(`minHeight=${minHeight}`);
    }
    const queryString = query.length > 0 ? `?${query.join("&")}` : "";

    return `/api/Images/${imageId}${queryString}`;
}
