/**
 * Maintains a promise value that can be awaited until the owner completes.
**/
export function createCompletion () {
    let _resolve
    const createPromise = () => new Promise((resolve) => {
        _resolve = resolve
    })

    let promise = createPromise()

    return {
        get value () {
            return promise
        },
        complete: () => _resolve(),
        reset: () => {
            promise = createPromise()
        }
    }
}
