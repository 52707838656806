<!--
    A component which abstracts a sticky element which calculates the top position relative to its
    containing element (offsetTop).
-->

<template>
    <div class="app-sticky">
        <div :style="{'top': top + 'px'}">
            <slot>Test</slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            top: 0
        };
    },
    async mounted() {
        await this.setTop();
    },
    methods: {
        async setTop() {
            await this.$nextTick();
            if(this.$el == null) {
                return 0;
            }
            this.top = this.$el.offsetTop;
        }
    }
}
</script>

<style>
    .app-sticky > * {
        position: sticky;
    }
</style>
