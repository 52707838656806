import { format as formatCurrency } from "./currencyFormatter";

export const types = [
    "compensation"
];

export function format(value, type) {
    return formatCurrency(value) + formatCompensationType(type);
}

function formatCompensationType(type) {
    switch (type) {
        case "Hourly":
            return "/hour"

        case "Annual":
            return "/year"

        default:
            return "/shift"
    }
}
