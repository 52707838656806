<template>
    <v-app>
        <template v-if="errors.length">
            <v-container class="fill-height">
                <div class="d-flex flex-column">
                    <h1>
                        An error has occured while loading.
                    </h1>
                    <p v-for="error in errors" :key="error" class="text-h6">
                        {{ getErrorMessage(error) }}
                    </p>
                </div>
            </v-container>
        </template>
        <template v-else-if="isLoaded">
            <the-alert />
            <the-app-bar v-if="isLoggedIn" @menu-toggled="isMenuOpen = true" />
            <v-main :class="{main: isLoggedIn}">
                <router-view class="router-container" />
            </v-main>
            <the-app-navigation-drawer v-model="isMenuOpen" />
        </template>
        <template v-else>
            <div class="loading d-flex align-center justify-center flex-grow-1">
                <v-progress-circular
                    :size="70"
                    :width="7"
                    indeterminate
                    color="tertiary" />
            </div>
        </template>
    </v-app>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { getErrorMessage } from "@/services/errorUtility";

import "@fontsource/inter/400.css"; // Regular
import "@fontsource/inter/500.css"; // Medium
import "@fontsource/inter/600.css"; // Semi-bold
import "@fontsource/inter/700.css"; // Bold
import "@fontsource/inter/800.css"; // Extra-bold

export default {
    components: {
        theAlert: () => import("@/components/TheAlert"),
        theAppBar: () => import("@/features/app-bar/TheAppBar"),
        theAppNavigationDrawer: () => import("@/features/app-bar/TheAppNavigationDrawer"),
    },
    data() {
        return {
            isMenuOpen: false
        }
    },

    computed: {
        ...mapState({
            isLoggedIn: s => s.authentication.isLoggedIn,
            user: s => s.authentication.user,
        }),

        ...mapGetters({
            isLoaded: "load/isLoaded",
            errors: "load/errors"
        }),
    },

    async mounted() {
        this.$router.onReady(this.onStartup)
    },


    methods: {
        ...mapActions({
            onStartup: "load/onStartup"
        }),
        getErrorMessage
    }
};
</script>

<style lang="scss">
    @import "styles/theme.scss";
    @import "styles/typography.scss";
    @import "styles/components.scss";
    @import "styles/utils.scss";

    #app, .loading, .router-container {
        background-color: $color-white;
        .v-text-field .v-input__slot {
            background-color: $color-white;
        }
    }
</style>

<style lang="scss" scoped>
    // v-main dynamically calculates some top padding after the page displays. Here we are
    // hardcoding the results of this calculation. This fixes issues with apex charts, where it
    // calculates the height of the chart before the v-main padding is determined. This also
    // prevents the whole page popping down by 48 pixels.
    .main {
        padding-top: 64px !important;
    }

    .router-container {
        width: 100%;
        height: 100%;
    }
</style>
